import React from 'react'
// import { render } from 'react-dom'
import { Parallax } from 'react-parallax'
// import Introduction from './Introduction'
import MenuNCode from '../../header/HeaderNCode'
import NCodeServices from '../../data/services.json'
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap'
import Footer3 from '../../footer/Footer3'
// import Steps from '../../home/homeThree/content/Steps'
import ImgBlock from '../../blocks/img'
import RenderIconsByID from '../../utils/renderIcons';
import { IconContext } from 'react-icons'


// https://dev.to/quocbahuynh/build-a-multi-language-website-using-react-in-3-minutes-4206
const styles = {
  FontFace: 'sans-serif',
  textAlign: 'center',
}
const insideStyles = {
  background: 'white',
  padding: 20,
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
}

const DataAnalysis = () => (
  <div style={styles}>
    <MenuNCode />
    {/* bgImage={image1}  bgImage={bggradient} */}
    {/* First Session, Carousel with Website Products */}
    <Parallax strength={70}>
      <Container fluid='xxl=auto, md=auto' className='session-container-pad0'>
        <Row>
          <Col >
            <Carousel fade={true}>
              <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[1]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />
                <Carousel.Caption className="">
                  <Container fluid>
                    <Row >
                      <Col className='vh-align block-message-title-2'>
                        <h1>Trasforme Livros  em Sistemas 🚀.</h1>
                        <h2>Veja as informações em tempo real.
                        </h2>
                        <p>Acessíveis em qualquer lugar 🌎, Laptop, Mobile, Tablet.</p>
                        <Button variant="outline-light" size="" >Saiba Mais</Button>
                      </Col>
                      <Col className='col-img-right'>
                        <img
                          src={NCodeServices.design.parallax[1]}
                          alt="Transform your Data"
                          className="*d-block w-65 d-none1"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[0]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />
                <Carousel.Caption className="">
                  <Container fluid>
                    <Row >
                      <Col className='col-img-left'>
                        <img
                          src={NCodeServices.design.parallax[0]}
                          alt="Transform your Data"
                          className="*d-block w-65 d-none1"
                        />
                      </Col>
                      <Col className='vh-align block-message-title-1'>
                        <h1>Transforme seu negócio conosco</h1>
                        <h2>Estamos aqui para fazer seus números falarem.
                        </h2>
                        <p>Seja no Excel, PowerPoint, Power Bi, Website ou em que você precisar</p>
                        <Button variant="outline-light" size="" >Saiba Mais</Button>
                      </Col>
                    </Row>
                  </Container>

                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[2]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />
                <Carousel.Caption className="">
                  <Container fluid>
                    <Row >
                      <Col className='vh-align block-message-title-3'>
                        <h1>Conecte tudo em  🚀.</h1>
                        <h2>Veja as informações em tempo real.
                        </h2>
                        <p>Acessíveis em qualquer lugar 🌎, Laptop, Mobile, Tablet.</p>
                        <Button variant="outline-light" size="" >Saiba Mais</Button>
                      </Col>
                      <Col className='col-img-right'>
                        <img
                          src={NCodeServices.design.parallax[1]}
                          alt="Transform your Data"
                          className="*d-block w-65 d-none1"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item> */}
              <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[2]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />
                <Carousel.Caption>
                  <h3>Agora, Conecte Tudo <br />E obtenha muito mais dos seus negócios.</h3>
                  <p>Quer saber mais, dá um clique abaixo.</p>
                  {/* <h3>ตอนนี้เชื่อมต่อทั้งหมด<br />และรับประโยชน์มากขึ้นจากธุรกิจของคุณ</h3>
                  <p>หากต้องการทราบข้อมูลเพิ่มเติม คลิกด้านล่าง</p>                   */}
                  <Button variant="outline-light" size="" >Saiba Mais</Button>
                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[2]}
                  alt="Transform your Data"
                  className="d-block w-100"
                  height={'70%'}
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[3]}
                  alt="Transform your Data"
                  className="d-block w-100"
                  height={'70%'}
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item> */}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Parallax>
    {/* First Session, Carousel with Website Products */}
    {/* Second Session, Information about Products and Services */}
    <Parallax>
      <Container style={{ height: '25vh', marginTop: '20px' }}>
        <Row>
          <Col className="block-message">
            <span className='p-21'>
              <p>A N-Code foi criada por profissionais de diversos ramos de Negócios, como Bancário, Varejo, Telecomunicações e Seguros. Com mais de 15 de Experiência em diversos segmentos, agora trabalhando para fazer <b>A SUA EMPRESA CRESCER.</b></p>
            </span>
          </Col>
        </Row>
      </Container>
    </Parallax>
    <Parallax>
      <Container style={{ height: '55vh', marginTop: '20px' }}>
        <Row>
          <Col className="block-message">
            <h2 className="title">
              Somos {" "}
              <span className="text-theme">Únicos</span>
            </h2>
            <p>
              Nossa experiência em vários setores nos permite oferecer soluçoes completas para o sua empresa.
            </p>
            <p className='p-21'>
              Nosso objetivo é fazer sua empresa crescer sustentávelmente, automatizando seus processos, economizando recursos financeiros e principalemnte naturais, criando ferramentas uteis para seus colaboradores e clientes.
            </p>
          </Col>
          <Col>
            <ImgBlock imgsrc="images/about/02.png" />
          </Col>
        </Row>
      </Container>
    </Parallax>
    {/* Second Session, Information about Products and Services */}
    {/* bgImage={NCodeServices.design.bg[0]} */}
    <h1>| | |</h1>
    <Parallax strength={50} bgImageSize={50} className='' >
      <Container style={{ height: '55vh', marginTop: '20px' }} className='vh-align'>
        <Row className=''>
          <Col>
            <ImgBlock imgsrc="images/svg/03.svg" />
          </Col>
          <Col md="1">&nbsp;
          </Col>
          <Col className="block-message block-message-white1">
            <h2 className="title">
              O que nos faz {" "}
              <span className="text-theme">Fortes</span>
            </h2>
            <p>
              É trabalhar por mais de 15 anos com diversos projetos .
            </p>
            <p className='p-21'>
              Entendemos bem o seu projeto pois já estivemos do mesmo lado,
              desenvolvendo diferentes tipos de projetos para grandes empresas.
            </p>
            <p className='p-21'>
              O nosso diferencial é o comprometimento com os seus resultados.
            </p>
          </Col>

        </Row>
      </Container>
    </Parallax>

    <h1>| | |</h1>
    {/* bgImage={NCodeServices.design.bg[1]} */}
    <Parallax strength={-100}>
      <Container style={{ height: '55vh', marginTop: '20px' }}>
        <Row>
          <Col className="block-message">
            <h2 className="title">
              Se {" "}
              <span className="text-theme">Sua empresa travou em um projeto.</span> e a equipe de TI não consegue achar a solução.
            </h2>
            <p className='p-21'>
            </p>
            <p className='p-21'>
              Fale conosco e ajudamos vocês a destravar.
            </p>
            <p className='p-21'>
              Nossa equipe fará uma análise técnica do problema e com certeza achará a solução.
            </p>
          </Col>
          <Col>
            <ImgBlock imgsrc={NCodeServices.design.gallery[0]} rounded={true} />
          </Col>
        </Row>
      </Container>
    </Parallax>
    <h1>| | |</h1>
    <Parallax
      // bgImage={image4}
      strength={200}
      renderLayer={(percentage) => (
        <div>
          <div
            style={{
              position: 'absolute',
              background: `rgba(47.8, 7.8, 50.6, ${percentage * 1})`,
              left: '50%',
              top: '50%',
              borderRadius: '50%',
              transform: 'translate(-50%,-50%)',
              width: percentage * 650,
              height: percentage * 650,
            }}
          />
        </div>
      )}
    >
      <Container fluid style={{ height: '100vh' }}>
        <Row>
          <Col>
            <div style={{ height: '100vh' }}>
              <div style={insideStyles}>
                <img src={'images/ncode/logo.png'} alt='N-code Business Technology Consulting'/>
                <h1>
                  Somos a Ponte 
                  <IconContext.Provider
													value={{
														color: '#25D366',
														size: '5em',
														className: 'global-class-name',
													}}
												>
													<RenderIconsByID icon={29} />
												</IconContext.Provider>                  
                  
                  entre a TI o Cliente
                </h1>
                <p>
                  Entendendo as necessidades do cliente e traduzindo para a área de TI.
                </p>
                <p>Traduzindo a parte técnica de uma forma amigável para os usuários </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Parallax>
    {/* <a href="https://www.flaticon.com/free-icons/random" title="random icons">Random icons created by Freepik - Flaticon</a> */}
    <Footer3 />
  </div>
)

export default DataAnalysis;
