import React from "react";
// import dataTeam from "../../../data/data.json";
// const URL = require("../../../constants");

const Products = () => {
	return (
		<>
			<section className="o-hidden pb-2">
				<div className="container">
					<div className="row text-center">
						<div className="col-lg-8 col-md-10 ml-auto mr-auto">
							<div className="section-title">
								<h2 className="title">
									Produtos N-Code
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<div
								className="owl-carousel owl-theme no-pb"
								data-dots="true"
								data-items="3"
								data-md-items="2"
								data-sm-items="1"
								data-autoplay="true"
							>

								<div className="item">
									<div className="team-member style-3">
										<div className="team-images">
											<img
												className="img-fluid radius box-shadow"
												src="images/about/01.png"
												alt=""
											/>{" "}
											<a
												className="team-link"
												href="/teamsingle"
											>
												<i className="la la-external-link"></i>
											</a>
										</div>

									</div>
								</div>
								<div className="item">
									<div className="team-member style-3">
										<div className="team-images">
											<img
												className="img-fluid radius box-shadow"
												src="images/about/02.png"
												alt=""
											/>{" "}
											<a
												className="team-link"
												href="/teamsingle"
											>
												<i className="la la-external-link"></i>
											</a>
										</div>

									</div>
								</div>
								<div className="item">
									<div className="team-member style-3">
										<div className="team-images">
											<img
												className="img-fluid radius box-shadow"
												src="images/about/03.png"
												alt=""
											/>{" "}
											<a
												className="team-link"
												href="/teamsingle"
											>
												<i className="la la-external-link"></i>
											</a>
										</div>

									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Products;
