import React, { useState } from "react";
import { Container, Row, Col} from 'react-bootstrap'

// type AppProps = {
//     imgsrc: string;
//   }; /* use `interface` if exporting so that consumers can extend */

// interface MessageProps {
//     chooseMessage: (params: any) => any;
//   }

const ImgBlock = ({ imgsrc, rounded, h, w }: any) => {
    const imgRounded = useState(rounded ? "rounded" : "")
    const height = useState(h ? h : "100")
    const width = useState(w ? w : "100")

    return (
        <Container>
            <Row>
                <Col className="post-image">
                    <div className="post">
                        <div className="post-image">
                            <img
                                className={`img-fluid h-${height} w-${width} ${imgRounded}`} 
                                src={imgsrc}
                                alt=""
                            />{" "}
                            <a
                                className="post-categories d-none"
                                href="/#"
                            >
                                Saiba Mais
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};



export default ImgBlock;

