import React from 'react'
// import { render } from 'react-dom'
import { Parallax, Background } from 'react-parallax'
// import Introduction from './Introduction'
import MenuNCode from '../../header/HeaderNCode'
import NCodeServices from '../../../components/data/services.json'
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap'
import './style/style.css'
import Footer3 from '../../footer/Footer3'
// import Steps from '../../home/homeThree/content/Steps'
import ImgBlock from '../../blocks/img'
import RenderIconsByID from '../../utils/renderIcons';
import { IconContext } from 'react-icons'
import LogoSvg from '../../utils/logo-svg'
import ColorBar from '../../utils/ColorBar'
// import ScrollCheck from '../../utils/scrollCheck';


// https://dev.to/quocbahuynh/build-a-multi-language-website-using-react-in-3-minutes-4206
const styles = {
  FontFace: 'sans-serif',
  textAlign: 'center',
}
// const insideStyles = {
//   // backgroundImage:  'https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
//   background: `url(https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) rgba(47.8, 7.8, 50.6, 0)`,
//   padding: 20,
//   position: 'relative',
//   top: '25%',
//   left: '50%',
//   transform: 'translate(-50%,-50%)',
// }
// const isScrolled = ScrollCheck();

const HomePresentation = () => (
  <div style={styles}>
    <MenuNCode />
    {/* bgImage={image1}  bgImage={bggradient} className={isScrolled ? 'adjustTopSection' : ''}*/}
    {/* First Session, Carousel with Website Products */}
    <Parallax strength={70} className='topPage'>
      <Container fluid='xxl=auto, md=auto' className='session-container-pad0'>
        <Row>
          <Col >
            <Carousel fade={true}>
              <Carousel.Item  className="d-none1" interval={1000000}>
                <img
                  src={`${NCodeServices.design.urlprefix}${NCodeServices.design.multiresolution[0].src}`}
                  srcSet={NCodeServices.design.multiresolution[0].srcset}
                  sizes={NCodeServices.design.multiresolution[0].sizes}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />
                <Carousel.Caption className="carousel-caption-vh-middle">
                  <Container fluid>
                    <Row className='vh-align'>
                      <Col className='block-message-title-2'>
                        <h1>Trasforme seus Sistemas 🚀.</h1>
                        <h2>Tire do papel e veja as informações em tempo real.
                        </h2>
                        <p>Acessíveis em qualquer lugar 🌎, Laptop, Mobile, Tablet.</p>
                        <Button variant="outline-light" size="" >Saiba Mais</Button>
                      </Col>
                      <Col className='col-img-right'>
                        <img
                          src={NCodeServices.design.parallax[1]}
                          alt="Transform your Data"
                          className="*d-block w-65 d-none"
                        />
                        {/* <img
                          srcset="bg1-1920x1280hds-original-400w.avif 400w, bg1-1920x1280hds-original-600w.avif 600w, bg1-1920x1280hds-original-800w.avif 800w, bg1-1920x1280hds-original-1000w.avif 1000w, bg1-1920x1280hds-original-1200w.avif 1200w, bg1-1920x1280hds-original-1600w.avif 1600w"
                          sizes="(max-width: 400px) 400px, (max-width: 600px) 600px, (max-width: 800px) 800px, (max-width: 1000px) 1000px, (max-width: 1200px) 1200px, (min-width: 1201px) 1600px"
                          src={NCodeServices.design.parallax[1]}
                          alt=""
                          width="1920"
                          height="580"
                          loading="lazy"
                        /> */}
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item  className="" interval={1000000}>
              <img
                  src={`${NCodeServices.design.urlprefix}${NCodeServices.design.multiresolution[1].src}`}
                  srcSet={NCodeServices.design.multiresolution[1].srcset}
                  sizes={NCodeServices.design.multiresolution[1].sizes}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />                
                {/* <img
                  src={NCodeServices.design.carousel[0]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                /> */}
                <Carousel.Caption className="carousel-caption-vh-middle">
                  <Container fluid>
                    <Row className='vh-align'>
                      <Col className='col-img-left'>
                        <img
                          src={NCodeServices.design.parallax[0]}
                          alt="Transform your Data"
                          className="*d-block w-65 d-none"
                        />
                      </Col>
                      <Col className='block-message-title-1'>
                        <h1>Transforme seu negócio conosco</h1>
                        <h2>Estamos aqui para fazer seus números falarem.
                        </h2>
                        <p>Seja no Excel, PowerPoint, Power Bi, Website ou em que você precisar</p>
                        <Button variant="outline-light" size="" >Saiba Mais</Button>
                      </Col>
                    </Row>
                  </Container>

                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item  className="d-none1" interval={1000000}>
              <img
                  src={`${NCodeServices.design.urlprefix}${NCodeServices.design.multiresolution[2].src}`}
                  srcSet={NCodeServices.design.multiresolution[2].srcset}
                  sizes={NCodeServices.design.multiresolution[2].sizes}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                />  
                {/* <img
                  src={NCodeServices.design.carousel[2]}
                  alt="Transform your Data"
                  className="d-block w-100 container-first-session"
                /> */}
                <Carousel.Caption className='carousel-caption-vh-bottom'>
                  <h3>Agora, Conecte Tudo <br />E obtenha muito mais dos seus negócios.</h3>
                  <p>Quer saber mais, dá um clique abaixo.</p>
                  {/* <h3>ตอนนี้เชื่อมต่อทั้งหมด<br />และรับประโยชน์มากขึ้นจากธุรกิจของคุณ</h3>
                  <p>หากต้องการทราบข้อมูลเพิ่มเติม คลิกด้านล่าง</p>                   */}
                  <Button variant="outline-light" size="" >Saiba Mais</Button>
                </Carousel.Caption>
              </Carousel.Item>
              {/* <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[2]}
                  alt="Transform your Data"
                  className="d-block w-100"
                  height={'70%'}
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  src={NCodeServices.design.carousel[3]}
                  alt="Transform your Data"
                  className="d-block w-100"
                  height={'70%'}
                />
                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item> */}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Parallax>
    {/* First Session, Carousel with Website Products */}
    {/* Second Session, Information about Products and Services */}
    <Parallax>
      <Container style={{ height: '25vh', marginTop: '20px' }}>
        <Row>
          <Col sm md xl className="block-message">
            <span className='p-21'>
              <p>A N-Code foi criada por profissionais de diversos ramos de Negócios, como Bancário, Varejo, Telecomunicações e Seguros. Com mais de 15 de Experiência em diversos segmentos, agora trabalhando para fazer <b>A SUA EMPRESA CRESCER.</b></p>
            </span>
          </Col>
        </Row>
      </Container>
    </Parallax>
    <Parallax>
      <Container style={{ height: '55vh', marginTop: '20px' }}>
        <Row>
          <Col className="block-message">
            <h2 className="title">
              Somos {" "}
              <span className="text-theme">Únicos</span>
            </h2>
            <p>
              Nossa experiência em vários setores nos permite oferecer soluçoes completas para o sua empresa.
            </p>
            <p className='p-21'>
              Nosso objetivo é fazer sua empresa crescer sustentávelmente, automatizando seus processos, economizando recursos financeiros e principalemnte naturais, criando ferramentas uteis para seus colaboradores e clientes.
            </p>
          </Col>
          <Col>
            <ImgBlock imgsrc="images/ncode/pexels/pexels-photo-3182773.jpeg" />
          </Col>
        </Row>
      </Container>
    </Parallax>
    {/* Second Session, Information about Products and Services */}
    {/* bgImage={NCodeServices.design.bg[0]} */}
    {/* <ColorBar /> */}
    <Parallax strength={0} bgImageSize={80} className='' >
      <Container style={{ height: '65vh', marginTop: '50px' }} className='vh-align'>
        <Row className='vh-align'>
          <Col lg={6}>
            <ImgBlock imgsrc="images/ncode/pexels/pexels-thisisengineering-3861969.jpg" />
          </Col>
          <Col className="block-message block-message-white1">
            <h2 className="title">
              O que nos faz {" "}
              <span className="text-theme">Fortes</span>
            </h2>
            <p>
              É trabalhar por mais de 15 anos com diversos projetos .
            </p>
            <p className='p-21'>
              Entendemos bem o seu projeto pois já estivemos do mesmo lado,
              desenvolvendo diferentes tipos de projetos para grandes empresas.
            </p>
            <p className='p-21'>
              O nosso diferencial é o comprometimento com os seus resultados.
            </p>
          </Col>

        </Row>
      </Container>
    </Parallax>

    {/* bgImage={NCodeServices.design.bg[1]} */}
    <Parallax strength={-100}>
      <Container style={{ height: '55vh', marginTop: '100px' }}>
        <Row>
          <Col className="block-message">
            <h2 className="title">
              Se {" "}
              <span className="text-theme">Sua empresa travou em um projeto</span> e a equipe de TI não consegue achar a solução.
            </h2>
            <p className='p-21'>
            </p>
            <p className='p-21'>
              Fale conosco e ajudamos vocês a encontrar a solução.
            </p>
            <p className='p-21'>
              Nossa equipe fará uma análise técnica do problema e com certeza achará a solução.
            </p>
          </Col>
          <Col>
            <ImgBlock imgsrc={NCodeServices.design.gallery[0]} rounded={true} />
          </Col>
        </Row>
      </Container>
    </Parallax>
    <Parallax strength={300}>
      <Background className="custom-bg">
        <img src="https://images.pexels.com/photos/15065060/pexels-photo-15065060/free-photo-of-light-art-dark-abstract.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="fill murray" />
      </Background>
    </Parallax>
    <Parallax
      // bgImage={image4}
      strength={200}
      renderLayer={(percentage) => (
        <div>
          <div
            style={{
              position: 'absolute',
              background: `url(https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) rgba(47.8, 7.8, 50.6, ${percentage * 1})`,
              left: '50%',
              top: '50%',
              // borderRadius: '50%', User Curve Background
              transform: 'translate(-50%,-50%)',
              width: percentage * 650,
              height: percentage * 650,
            }}
          />

          {/* */}
          <Container fluid style={{ height: '75vh', marginTop: '150px' }}>
            <Row>
              <Col>
                <div style={{ height: '100vh' }}>
                  <div>
                    {/* <img src={`${URL.img_url}${dataHeader.data.home[0].logo}`} alt='N-code Business Technology Consulting'/> */}

                    <h1>
                      Somos a Ponte
                      <IconContext.Provider
                        value={{
                          color: '#25D366',
                          size: '5em',
                          className: 'global-class-name',
                        }}
                      >
                        <RenderIconsByID icon={29} />
                      </IconContext.Provider>

                      entre a TI o Cliente
                    </h1>
                    <p>
                      Entendendo as necessidades do cliente e traduzindo para a área de TI.
                    </p>
                    <p>Traduzindo a parte técnica de uma forma amigável para os usuários </p>
                    <a className="navbar-brand logo logo-purple" href="/">
                      <LogoSvg />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

      )}
    >

    </Parallax>
    {/* <a href="https://www.flaticon.com/free-icons/random" title="random icons">Random icons created by Freepik - Flaticon</a> */}

    <ColorBar />
    <Footer3 />
  </div>
)

export default HomePresentation;
