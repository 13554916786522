import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./style/style.css"


const ButtonTranslation = () => {

    const { i18n } = useTranslation();

    const lngs = [
        { code: "en", native: "EN-US", icon: "🇺🇸", },        
        { code: "pt", native: "PT-BR", icon: "🇧🇷" },
    ];

    const [lang, setLang] = useState(lngs[0].native)
    const [icon] = useState(lngs[0].icon)


    const handleTrans = (code: string, native: string) => {
        i18n.changeLanguage(code);
        setLang(native)
    };

    return (
        <DropdownButton id="dp-language" variant="primary" className="btn-translation p-2" title={icon} size="sm">
            {lngs.map((lng, i) => {
                const { code, native, icon } = lng;
                // 
                // active={ i === 0 ? true : false}
                return <Dropdown.Item key={i} 
                active={ native === lang ? true : false}
                href="#" rel="noopener noreferrer" onClick={() => handleTrans(code, native)} ><span>{icon}</span><span>{native}</span></Dropdown.Item>
            })}
        </DropdownButton>
    )
}

export default ButtonTranslation;