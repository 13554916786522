import { useState, useEffect } from 'react'

const ScrollCheck = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 80) {
                // alert('Moving > 80');
                setIsScrolled(true);
            } else {
                // alert('Moving < 80');
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isScrolled;
}

export default ScrollCheck;