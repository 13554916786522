import React from "react";
// import dataHeader from '../../data/data.json'
import './style/style.css'

interface Props {
    logoResize: string,
}

const LogoSvg: React.FC<Props> = ({ logoResize }: Props) => {
    return (
        <img src="images/logo-center.png" alt="" />
        // <object aria-label='N-code'
        //     id="ncode_logo_01"
        //     className='img-center'
        //     data={logoResize ? dataHeader.data.home[0].logosm : dataHeader.data.home[0].logo}
        // />
        // <img src={`${dataHeader.data.home[0].logo}`} alt="N-Code Business Consulting Technology" />
    )
}

export default LogoSvg;

