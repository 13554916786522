import React from "react";
import NCodeServices from '../../../components/data/services.json';
import RenderIconsByID from '../../utils/renderIcons';
import { IconContext } from 'react-icons'
import './style.css';
// import "./translations";
import ButtonTranslation from "../../utils/translator";
import { useTranslation } from 'react-i18next';

const ComingSoon = () => {

	const { t } = useTranslation();

	// const [email, setEmail] = useState("");

	// useEffect(() => {
	// 	setEmail(email)
	//   }, [email])

	return (
		<div className="page-content">
			<section
				className="fullscreen-banner p-0 o-hidden text-center dark-bg grediant-overlay"
				data-bg-img={NCodeServices.design.carousel[2]}
				data-overlay="8"
			>

				<canvas id="confetti"></canvas>
				<div className="page-title-pattern">
					<img className="img-fluid border-gradient-purple border-gradient" src="images/bg/13.png" alt="" />
				</div>
				<div className="align-top z-index-1">
					<div className="container-fluid" style={{ marginTop: "10px" }}>
						<div className="row">
							<div className="col-6">
							</div>
							<div className="col-6 align-right">
								<ButtonTranslation />
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="coming-soon">
									<a className="logo" href="/">
										<img
											id="logo-img"
											className="img-center"
											src="images/ncode/logo-white.png"
											alt=""
										/>
									</a>
									<h1 className="text-uppercase mt-4 mb-2 text-white">
										{t('comingsoon.weare')} {" "}
										<span className="text-black">{t('comingsoon.coming')}</span>
									</h1>
									<h4 className="title z-index-1 mb-2 text-white">
										{t('comingsoon.bringingitsolution')}
									</h4>
									<h4 className="title z-index-1 mb-5 text-white">
										{t('comingsoon.speciallydesign')}
									</h4>									
									<ul
										className="countdown1 d-none list-inline text-white"
										data-countdown="2023/11/02"
									></ul>
									<div>
										<h4 className="title z-index-1 mb-5 text-white">
											{t('comingsoon.needhelpwith')} {" "}
											<span> {t('comingsoon.itprojects')}</span>
										</h4>
										<h4 className="title z-index-1 mb-2 text-white d-none">
											{t('comingsoon.subscribe')}{" "}
											<span> {t('comingsoon.moreinformation')}</span>
										</h4>
										<div className="row d-none">
											<div className="col-md-6 mr-auto ml-auto mb-2">
												<div className="subscribe-form">
													<form
														id="mc-form"
														className="group"
													>
														<input
															type="email"
															// value={email}
															// onChange={setEmail}
															name="EMAIL"
															className="email box-shadow"
															id="mc-email"
															placeholder={t('comingsoon.youremail')}
															required=""
														/>
														<input
															className="btn btn-theme"
															type="submit"
															name="inscrevase"
															value="Inscreva-se"
														/>
														<label
															for="mc-email"
															className="subscribe-message"
														></label>
													</form>
												</div>
											</div>
										</div>

									</div>
									<div className="comingsoon">
										<h4 className="title z-index-1 mb-2 text-white">
											{t('comingsoon.sendusamessage')}{" "} 
										</h4>
										<h4 className="title z-index-1 mb-2 text-white">
											{t('comingsoon.weansweryou')} <RenderIconsByID icon={27} />
										</h4>
										<h4 className="title z-index-1 mb-2 text-white">
											<a href="https://api.whatsapp.com/send?phone=13324550267&text=*Contato%20NCode:%20(Escreva%20sua%20mensagem%20aqui)%0a_________________________%0a"
												target="_blank" style={{ textDecoration: 'none', position: 'relative' }}
												rel="noopener noreferrer"
												alt={t('comingsoon.sendwhatsapp')}>{t('comingsoon.sendwhatsapp')} <IconContext.Provider
													value={{
														color: '#25D366',
														size: '1.5em',
														className: 'whatsapp-icon'
													}}
												>
													<RenderIconsByID icon={26} />
												</IconContext.Provider>
											</a>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default ComingSoon;
