import React, { useState } from 'react'
//import { useLocation } from 'react-router-dom';
// import Loader from "./Loader";
import dataHeader from '../data/data.json'
import menuNCode from '../data/menu.json'
import ColorBar from '../utils/ColorBar'
import ButtonTranslation from "../../components/utils/translator";
import { useTranslation } from 'react-i18next';
//import './theme-color/color-3.css'
import './style.css'
import LogoSvg from '../utils/logo-svg';
// import LogoSvgSm from '../utils/logo-svg-sm';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ScrollCheck from '../utils/scrollCheck';
// import { IonIcon } from '@ionic/react';
import RenderIconsByID from '../utils/renderIcons';
const HeaderNCode = () => {


  //let location = useLocation();
  // if(location.pathname === "/home-three"){
  // 	require('./theme-color/color-3.css');
  // }
  const storedPage = localStorage.getItem('page')
  const [breadcrumb] = useState(storedPage)
  const fun = (value) => {
    localStorage.setItem('page', value)
  }

  const isScrolled = ScrollCheck();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };  

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     if (scrollPosition > 80) {
  //       // alert('Moving > 80');
  //       setIsScrolled(true);
  //     } else {
  //       // alert('Moving < 80');
  //       setIsScrolled(false);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const MenuItemsReact = () => {

    const { t } = useTranslation();

    return (
      <>
        <Nav className='ml-auto' >
          {menuNCode.menu.map((title, index) => (
            (title.enabled === true) ?
              <NavDropdown.Item key={index} className={`nav-link nav-item ${breadcrumb === title.link ? 'active' : ''}`} onClick={() =>
                fun(
                  title.link
                )
              } href={title.link}>
                {t(`${title.translation}`)}
              </NavDropdown.Item>
              : ''
          ))}


        </Nav>
      </>
    )
  }
  const MenuItems = () => {

    const { t } = useTranslation();

    return (
      <>
        <ul id="main-menu" className="nav navbar-nav ml-auto mr-auto">
          {menuNCode.menu.map((title, index) => (
            (title.enabled === true) ?
              <li
                key={index}
                className={
                  'nav-item'
                }
              >
                <a
                  className={`nav-link ${breadcrumb === title.link ? 'active' : ''}`}
                  onClick={() =>
                    fun(
                      title.link
                    )
                  }
                  href={title.link}
                >
                  {t(`${title.translation}`)}
                  {/* {t("Empresa")} */}
                </a>
              </li>
              : ''
          ))}
        </ul>
      </>
    )
  }

  return (
    <>
      {/* <Loader /> */}
      <header id="site-header" className="header fixed-top">
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary1">
          <Container fluid className={isScrolled ? 'scrolled' : ''}>
            <Navbar.Brand href="#home" className='navbar-brand mr-auto logo1 logo-purple'>
              <LogoSvg logoResize={isScrolled} />
              {/* <LogoSvgSm className={isScrolled ? '' : 'd-none'} /> */}
            </Navbar.Brand>
            <Navbar.Collapse id="navNCode">
              <MenuItemsReact />
            </Navbar.Collapse>
            <ButtonTranslation />
            <Navbar.Toggle aria-controls="navNCode" onClick={handleToggle} className={isOpen ? 'rotateIcon' : ''}>
              <RenderIconsByID icon={isOpen ? 31 : 30}  className={isOpen ? 'open' : ''} />             
            </Navbar.Toggle>

          </Container>
        </Navbar>

        <div id="header-wrap" className='d-none'>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                {/* Navbar */}
                <nav className="navbar navbar-expand-lg ml-auto">
                  <a className="navbar-brand logo logo-purple" href="/">
                    <LogoSvg />
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    {' '}
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarNavDropdown"
                  >
                    {/* Left nav */}
                    <MenuItems />
                  </div>
                  {menuNCode.login.enabled === true ?
                    <a
                      className={
                        'btn btn-theme btn-sm ' + menuNCode.login.enabled ===
                          false
                          ? ' d-none'
                          : ''
                      }
                      href={`/${dataHeader.data.login.link}`}
                      data-text={'Login' + menuNCode.login.enabled}
                    >
                      {' '}
                      <span>L</span>
                      <span>o</span>
                      <span>g</span>
                      <span>i</span>
                      <span>n</span>
                      {console.log(menuNCode.login.enabled === false)}
                    </a>
                    : ''
                  }

                </nav>
                <ButtonTranslation />
              </div>
              {/* <div className="col-lg-2 align-right"> */}

              {/* </div> */}
            </div>
          </div>
        </div>
        <ColorBar />
      </header>
    </>
  )
}

export default HeaderNCode
