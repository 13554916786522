import React from "react";
import dataClient from "../../../data/data.json";
const URL = require("../../../constants");

const Clients = () => {
	return (
		<section className="custom-mt-15 grey-bg z-index-0 custom-pt-18 pb-3">
			<div className="container">
			<div className="row text-center">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-users"></i>
								</div>
								<h2 className="title">
									Nossos parceiros
								</h2>
							</div>
						</div>
					</div>
				<div className="row">
					<div className="col-md-12">
						<div
							className="owl-carousel owl-theme"
							data-dots="false"
							data-items="2"
							data-md-items="2"
							data-sm-items="2"
							data-margin="30"
							data-autoplay="false"
						>
							{dataClient.data.home[0].content.clients.map(
								(img, index) => (
									<div className="item" key={index}>
										<div className="clients-logo">
											<img
												className="img-center"
												src={`${URL.img_url}${img}`}
												alt=""
											/>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Clients;
