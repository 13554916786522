import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './components/data/locales/en/translation.json';
import translationPT from './components/data/locales/pt/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    // partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: resources
  });

  // i18n.addResourceBundle('en', 'en', {translationEN});
	// i18n.addResourceBundle('pt', 'pt', {translationPT});
 

export default i18n;