import React from "react";
// import { useForm } from "react-hook-form";
//import ColorCustomizer from "./ColorCustomizer";
import ScrollTop from "../ScrollTop";

const Footer3 = () => {
    // const { register, handleSubmit } = useForm();
    // const onSubmit = (data) => {
    //     alert(JSON.stringify(data));
    // };
    return (
        <>
            {/* footer start */}
            <footer
                className="footer pos-r"
                data-bg-img="images/bg/01.png"
            >
                <div className="primary-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-logo">
                                    <img
                                        id="footer-logo-img"
                                        src="images/logos/logo_italic1.png"
                                        className="img-center"
                                        alt=""
                                    />
                                </div>
                                <p className="mb-0">
                                    Uma empresa criada por profissinais de TI que entendem o que os clientes precisam.
                                    <br></br>
                                    Technologia só é boa quando é útil para os clientes.
								</p>
                            </div>
                            <div className="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
                               
                            </div>
                            <div className="col-lg-4 col-md-12 md-mt-5">
                                <ul className="media-icon list-unstyled">
                                    
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:abcd@gmail.com">
                                            <b>alex.vieira@n-code.co</b>
                                        </a>
                                    </li>
                                    <li>
                                        Whatsapp:{" "}
                                        <a href="tel:+912345678900">
                                            <b>+1 (332) 455-0267</b>
                                        </a>
                                    </li>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:abcd@gmail.com">
                                            <b>jardel@n-code.co</b>
                                        </a>
                                    </li>
                                    <li>
                                        Whatsapp:{" "}
                                        <a href="tel:+912345678900">
                                            <b>+55 (11) 455-0267</b>
                                        </a>
                                    </li>                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
             
                <div className="secondary-footer">
                    <div className="container">
                        <div className="copyright">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    {" "}
                                    <span>
                                    Copyright 2022 N-Code {" "} 									</span>
                                </div>
                                <div className="col-lg-6 col-md-12 text-lg-right md-mt-3">
                                    <div className="footer-social">
                                        <ul className="list-inline">
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-facebook-f"></i>{" "}
													Facebook
												</a>
                                            </li>
                                            <li className="mr-2">
                                                <a href="/#">
                                                    <i className="fab fa-twitter"></i>{" "}
													Twitter
												</a>
                                            </li>
                                            <li>
                                                <a href="/#">
                                                    <i className="fab fa-google-plus-g"></i>{" "}
													Google Plus
												</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* footer end */}
            {/* <ColorCustomizer /> */}
            <ScrollTop />
        </>
    );
};

export default Footer3;
