import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import HomeThree from "./home/homeThree";
import Clients from "./home/homeThree/content/Clients";
// import AboutUs from "./home/homeThree/content/AboutUs";
import About from "./pages/about";
import HomePresentation from "../components/pages/home/index.js";
import ComingSoon from "../components/pages/comingSoon";
import Company from "./pages/company";
import SystemsDevelopment from "./pages/systemsdevelopment";
import ItConsulting from "./pages/itconsulting";
import CloudComputing from "./pages/cloudcomputing";
import DataAnalysis from "./pages/dataanalysis";
import Contact from "./pages/contact";
// import Error404 from "./pages/error404";

function MainNavigation() {
  return (
    <Router>
      <Switch>
        {/** Home */}
        <Route path="/" exact={true} component={ComingSoon} /> 
        <Route path="/homeoff" exact={true} component={HomePresentation} /> 
        <Route path="/commpany" exact={true} component={Company} /> 
        <Route path="/aboutus" exact={true} component={About} /> 
        <Route path="/SystemsDevelopment" exact={true} component={SystemsDevelopment} /> 
        <Route path="/itconsulting" exact={true} component={ItConsulting} /> 
        <Route path="/dataanalysis" component={DataAnalysis} />
        <Route path="/cloudcomputing" component={CloudComputing} />
        <Route path="/clients" exact={true} component={Clients} /> 
        <Route path="/contact" exact={true} component={Contact} /> 
      </Switch>
    </Router>
  );
}

export default MainNavigation;
