import React from "react";
// import Header3 from '../../Header3';
// import Header1 from '../../Header1';
import HeaderNCode from "../../header/HeaderNCode";


import Footer3 from "../../footer/Footer3";
import AboutUs from "../../home/homeThree/content/AboutUs";
import Products from "../../home/homeThree/content/Products"
import Pricing from "../../home/homeThree/content/Pricing"


const About = () => (
  <>
      <HeaderNCode />
      <AboutUs />
      <Products />
      <Pricing />
      <Footer3 />
  </>
);
export default About;
