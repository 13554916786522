import React from 'react';
import '../../../components/theme-color/color-bars.css';

const ColorBar = () => {
  return (
    <>
      <div className="box"></div>
    </>
  );
};
export default ColorBar;
